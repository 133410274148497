import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import React from "react";
import { Provider, TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import appsReducer from '../features/apps/appsSlice'
import reactPlugin from "./appInsights";

export const createStore = () => configureStore({
    reducer: {
        apps: appsReducer,
    },
})

export const wrapWithProvider = ({ element }: any) => {
    const store = createStore()
    return (
        <AppInsightsContext.Provider value={reactPlugin}>
            <Provider store={store}>
                {element}
            </Provider>
        </AppInsightsContext.Provider>
    )
}

type ConfiguredStore = ReturnType<typeof createStore>
type StoreGetState = ConfiguredStore["getState"]
export type RootState = ReturnType<StoreGetState>
export type AppDispatch = ConfiguredStore["dispatch"]
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
