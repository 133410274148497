import { createSelector, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../context/store'

export type MyApp = {
    description: string
    identifier: string
    imageAlt: string
    isVisible: boolean
    title: string
    url: string
}

export type AppsState = {
    list: MyApp[]
}

export const AppsInitialState: AppsState = {
    list: [
        { url: '/apps/golf', identifier: 'course-handicap', isVisible: true, title: 'Course Handicap', description: 'A simple calculator for determining your course handicap based on your index.', imageAlt: 'Golf Green' },
        { url: '/apps/meme-maker', identifier: 'meme-maker', isVisible: true, title: 'Meme Maker', description: 'Fast and easy way to create memes.', imageAlt: 'Memes' },
        { url: '/apps/qr-code', identifier: 'qr-code', isVisible: true, title: 'QR Code Generator', description: 'Create and download custom QR Codes for free.', imageAlt: 'QR Code Generator' },
        { url: '/apps/rain', identifier: 'rain', isVisible: false, title: 'Rain Gauge', description: 'A simple rain guage for the last few weeks of rain.', imageAlt: 'Rain on a roof' },
        { url: '/apps/storm-distance-calculator', identifier: 'storm-distance-calculator', isVisible: true, title: 'Storm Distance Calculator', description: 'Use this tool to calculate the approximate distance of a thunder storm.', imageAlt: 'Lightning' },
        { url: '/apps/voltage-divider-calculator', identifier: 'voltage-divider-calculator', isVisible: true, title: 'Voltage Divider Calculator', description: 'A simple calculator for a voltage divider circuit.', imageAlt: 'Spark Across Two Wires' },
    ]
}

export const providersSlice = createSlice({
    name: 'apps',
    initialState: AppsInitialState,
    reducers: {
    },
});

// Base Selectors
export const selectString = (_: RootState, s: string) => s;
export const selectAppsBase = (state: RootState) => state.apps.list;

// Reselectors
export const selectApps = createSelector(selectAppsBase, (items) => items);
export const selectAppByIdentifier = createSelector(
    selectAppsBase,
    selectString,
    (items, identifier) => {
        return items.find(i => i.identifier === identifier)
    }
)
export const selectVisibleApps = createSelector(
    selectAppsBase,
    (items) => {
        if (process.env.NODE_ENV === 'production')
            return items.filter(i => i.isVisible === true)
        else
            return items
    }
)

export const { } = providersSlice.actions;
export default providersSlice.reducer;
