import { ApplicationInsights } from "@microsoft/applicationinsights-web"
import { ReactPlugin } from "@microsoft/applicationinsights-react-js"

var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
    config: {
        connectionString: process.env.APPINSIGHTS_CS,
        enableAutoRouteTracking: true,
        extensions: [reactPlugin],
    }
});

appInsights.loadAppInsights();

export default reactPlugin
